import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { Container } from "reactstrap"
import TemplateLayout from "../components/Layout/template-layout"
import styled from "styled-components"
import SEO from "../components/seo"

const StyledMarkdown = styled.div``

const DevelopmentTeam = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { title: {eq: "Development Team" } } }
      ) {
        nodes {
          id
          frontmatter {
            title
            subtitle
          }
          html
        }
      }
    }
  `)

  const { frontmatter, html } = data.allMarkdownRemark?.nodes?.[0] ?? {frontmatter:{}, html: ""}

  return (
    <TemplateLayout title={frontmatter.title}>
      <SEO title={frontmatter.title} />
      <Container style={{ paddingTop: "65px", paddingBottom: "65px" }}>
        <StyledMarkdown>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </StyledMarkdown>
      </Container>
    </TemplateLayout>
  )
}

export default DevelopmentTeam
